import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import ProductService from "../services/ProductService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeDropper,
  faEyeSlash,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UpdateImagesVariantPopup from "./ProductPopupModel/updateImagesVariantPopup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CategoryService from "src/services/CategoryService";
import urlsCommon from "src/common/urls-common";
import VariantsService from "src/services/VariantsService";
import UpdateOrderImagesVariant from "./ProductPopupModel/updateOrderImagesVariant";
import Circle from "src/common/circle";
import ShowVariantStock from "./ProductPopupModel/showVariantStock";

function DetailsProduct() {
  // const { isProductDetails } = props;
  const [hex, setHex] = useState("#F44E3B");
  const animatedComponents = makeAnimated();
  const animatedComponentstwo = makeAnimated();

  //************for show stock *********/
  const [sizesVariantStock, setSizesVariantStock] = useState([]);

  // const isProductDetails = localStorage.getItem("isProductDetails");
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [index, setIndex] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [variantSelectedImagesOrder, setVariantSelectedImagesOrder] =
    useState("");

  //****les produits similaires */
  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsDefault, setSelectedProductsDefault] = useState([]);

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
    console.log("Selected Products:", selectedOptions);
    console.log(selectedProducts);
  };

  //****Idee Look Produit */
  const [selectedProductsLook, setSelectedProductsLook] = useState([]);
  const [selectedProductsDefaultLook, setSelectedProductsDefaultLook] =
    useState([]);

  const handleProductLookChange = (selectedOptions) => {
    setSelectedProductsLook(selectedOptions);
    console.log(selectedProductsLook);
    console.log(selectedOptions);
  };

  useEffect(() => {
    getProdcutDetailsById(localStorage.getItem("isProductDetails"));
    getAllCategory();
    getAllProductsForSelectSimilaires();
    getSimilarProductsforProduct(localStorage.getItem("isProductDetails"));
    getIdeeLookProductsforProduct(localStorage.getItem("isProductDetails"));
  }, []);

  // useEffect(() => {
  //   getAllProductsForSelectSimilaires();
  //   getSimilarProductsforProduct();
  //   getIdeeLookProductsforProduct();
  // }, []);

  const addIdeeLookProducts = async (e) => {
    e.preventDefault();
    const productsLook = JSON.stringify(
      selectedProductsLook.map((product) => product.value)
    );
    // console.log(productsSimilaires);
    ProductService.addIdeeLookProducts(product.id, productsLook)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getIdeeLookProductsforProduct(product.id);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };

  const getIdeeLookProductsforProduct = (idProducts) => {
    ProductService.getIdeeLookProductsforProduct(idProducts)
      .then((response) => {
        if (response.data.success) {
          setSelectedProductsDefaultLook(response.data.success);
        }
        console.log("Les produits similaires ", response.data.success);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };

  const addSimilarProducts = async (e) => {
    e.preventDefault();
    const productsSimilaires = JSON.stringify(
      selectedProducts.map((product) => product.value)
    );
    // console.log(productsSimilaires);
    ProductService.addSimilarProducts(product.id, productsSimilaires)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getSimilarProductsforProduct(product.id);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };

  const getSimilarProductsforProduct = (idProducts) => {
    ProductService.getSimilarProductsforProduct(idProducts)
      .then((response) => {
        if (response.data.success) {
          setSelectedProductsDefault(response.data.success);
        }
        console.log("Les produits similaires ", response.data.success);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  const getAllProductsForSelectSimilaires = () => {
    ProductService.getAllProductsForSimilarProductsOrIdeeLook()
      .then((response) => {
        if (response.data.success) {
          setListAllProducts(response.data.success);
          console.log("listAllProducts", response.data.success);
        }
        console.log("listAllProducts", response.data.success);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };

  const getAllCategory = () => {
    CategoryService.getAllCategoriesBySousFamille()
      .then((response) => {
        if (response.data.object) {
          setCategory(response.data.object);
          console.log("category", response.data.object);
        }
        console.log("category", response.data.object);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  const getProdcutDetailsById = (idProducts) => {
    ProductService.getProductDetailsById(idProducts)
      .then((response) => {
        if (response.data.success) {
          setProduct(response.data.success);
        }
        console.log("product", response.data.success);
      })
      .catch((e) => {
        // setOpenErrorAlert(true);
        // setErrorAlertText(e);
      });
  };
  //***********for edit variant
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const openModalEdit = (index) => {
    setIsModalOpenEdit(true);
    setIndex(index);
    setIsSaved(false);
  };
  //***********for show  variant
  const [isModalOpenShowStock, setIsModalOpenShowStock] = useState(false);
  const openModalShowStock = (sizesVariant) => {
    setIsModalOpenShowStock(true);
    setSizesVariantStock(sizesVariant);
    setIsSaved(false);
  };

  //************for edit category product
  const [isModalOpenEditCategoryProduct, setIsModalOpenEditCategoryProduct] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleEditCategory = async (e) => {
    e.preventDefault();
    if (selectedCategory != null) {
      try {
        const response = await ProductService.updateCategoryProduct(
          product?.id,
          selectedCategory
        );

        if (response.data.success) {
          toast.success(response.data.success);
          setIsModalOpenEditCategoryProduct(false);
          getProdcutDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.error("Error editing category:", error);
        toast.error("An error occurred while editing the category.");
      }
    } else {
      toast.error("If faut selectionner category");
    }
  };

  //*********** */ for edit description product
  const [isModalOpenEditDescProduct, setIsModalOpenEditDescProduct] =
    useState(false);
  const [descriptionProduct, setDescriptionProduct] = useState("");

  const handleEditDesc = async (e) => {
    e.preventDefault();
    if (descriptionProduct !== "") {
      // Check for an empty string instead of null
      try {
        const response = await ProductService.updateDescriptionProduct(
          product?.id,
          descriptionProduct
        );

        if (response.data.success) {
          setIsModalOpenEditDescProduct(false);
          getProdcutDetailsById(product?.id);
          toast.success(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.error("Error editing Desc:", error);
        toast.error("An error occurred while editing the Desc.");
      }
    } else {
      toast.error("Il faut modifier la description du produit");
    }
  };

  //*********** */ for edit libelle site product
  const [
    isModalOpenEditLibelleSiteProduct,
    setIsModalOpenEditLibelleSiteProduct,
  ] = useState(false);
  const [LibelleSiteProduct, setLibelleSiteProduct] = useState("");

  const handleEditLibelleSite = async (e) => {
    e.preventDefault();
    if (LibelleSiteProduct !== "") {
      // Check for an empty string instead of null
      try {
        const response = await ProductService.updateLibelleSiteProduct(
          product?.id,
          LibelleSiteProduct
        );

        if (response.data.success) {
          toast.success(response.data.success);
          setIsModalOpenEditLibelleSiteProduct(false);
          getProdcutDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.error("Error editing LibelleSite:", error);
        toast.error("An error occurred while editing the LibelleSite.");
      }
    } else {
      toast.error("Il faut modifier la LibelleSite du produit");
    }
  };

  //******for edit Variant default product for front office
  const [
    isModalOpenEditDefaultVariantProductFoProduct,
    setIsModalOpenEditDefaultVariantProductFoProduct,
  ] = useState(false);
  const [selectedDefaultVariantProductFo, setSelectedDefaultVariantProductFo] =
    useState("");
  const handleEditDefaultVariantProductFo = async (e) => {
    e.preventDefault();
    if (selectedDefaultVariantProductFo != null) {
      try {
        console.log(product?.id);
        const response =
          await VariantsService.updateVariantDefaultProductToShowInFo(
            selectedDefaultVariantProductFo,
            product?.id
          );

        if (response.data.success) {
          toast.success(response.data.success);
          setIsModalOpenEditDefaultVariantProductFoProduct(false);
          getProdcutDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        console.error("Error editing DefaultVariantProductFo:", error);
        toast.error(
          "An error occurred while editing the DefaultVariantProductFo."
        );
      }
    } else {
      toast.error("If faut selectionner DefaultVariantProductFo");
    }
  };
  //*****for visibiité and archived variant Product */
  const archiverVariantById = (idVariant) => {
    VariantsService.archiver(idVariant)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getProdcutDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };

  const visibledVariantById = (idVariant) => {
    VariantsService.visible(idVariant)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          getProdcutDetailsById(product?.id);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API:", error);
      });
  };
  //******************for edit images variants order */
  const [
    isModalOpenEditOrderImagesVariant,
    setIsModalOpenEditOrderImagesVariant,
  ] = useState(false);
  const openModalEditOrderImagesVariant = (variantSelected) => {
    setIsModalOpenEditOrderImagesVariant(true);
    setVariantSelectedImagesOrder(variantSelected);
    setIsSaved(false);
  };

  return (
    <>
      <div className="content-wrapper">
        <ToastContainer />
        <section className="content-header">
          <div className="container-fluid">
            <div className="col-md-12">
              <div className="card card-secondary">
                <div
                  className="card-header"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h3 className="card-title">
                    Détails du produit
                    <h4 className="my-3">{product?.name || ""}</h4>
                  </h3>
                  <div style={{ marginLeft: "auto" }}>
                    {/* <button
                      className="btn btn-dark mr-2"
                      onClick={() => setProductCliqued(false)}
                    >
                      Retour
                    </button> */}
                    <button
                      className="btn btn-light"
                      onClick={() => {
                        window.open(
                          urlsCommon.URLS_SITE +
                            "article/" +
                            (product?.slug || ""),
                          "_blank"
                        );
                      }}
                    >
                      Voir dans le site
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <h3 className="d-inline-block d-sm-none">
                        {product?.name || ""}
                      </h3>
                      <div className="col-12">
                        <img
                          src={
                            (product.variants &&
                              product.variants.find(
                                (variant) => variant.defaultToFo
                              )?.imagesVariant[0]?.urlImage) ||
                            ""
                          }
                          style={{ width: "569px", height: "600px" }}
                          className="product-image"
                          alt="Product Image"
                        />
                      </div>
                      <div className="col-12 product-image-thumbs">
                        {product.variants &&
                          product.variants
                            .find((variant) => variant.defaultToFo)
                            ?.imagesVariant.map((imageVariant, index) => (
                              <>
                                <div
                                  className="product-image-thumb"
                                  key={index}
                                >
                                  <img
                                    src={imageVariant?.urlImage || ""}
                                    alt="Product Image"
                                  />
                                </div>
                              </>
                            ))}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <h4 className="my-3 float-right">
                        {product?.codeArticle ? (
                          <span class="right badge badge-success">
                            {product?.codeArticle}
                          </span>
                        ) : (
                          <span class="right badge badge-danger">
                            pas de code article
                          </span>
                        )}
                      </h4>
                      <br />
                      <h5 className="my-3">
                        <>
                          <label htmlFor="Category" className="mr-2">
                            Category :
                          </label>
                          {isModalOpenEditCategoryProduct ? (
                            <>
                              <>
                                {/* Edit Category Form */}
                                <div className="edit-category-form">
                                  <select
                                    className="form-control"
                                    value={
                                      selectedCategory || product?.category?.id
                                    }
                                    onChange={(e) =>
                                      setSelectedCategory(e.target.value)
                                    }
                                  >
                                    {category &&
                                      category.map((categoryItem, index) => (
                                        <>
                                          <option value={categoryItem.id}>
                                            {categoryItem.name}
                                          </option>
                                        </>
                                      ))}
                                  </select>
                                  <div className="float-right mt-2">
                                    <button
                                      className="btn btn-sm btn-warning mr-2"
                                      onClick={(e) => handleEditCategory(e)}
                                    >
                                      Modifier
                                    </button>
                                    <button
                                      className="btn btn-sm btn-secondary"
                                      onClick={() =>
                                        setIsModalOpenEditCategoryProduct(false)
                                      }
                                    >
                                      Annuler
                                    </button>
                                  </div>
                                </div>
                              </>
                            </>
                          ) : (
                            <>
                              {product?.category?.name || "Pas de category"}
                              <label htmlFor="Category" className="ml-2">
                                Sous Famille :
                              </label>
                              {product?.sousFamille?.name || ""}
                              <button
                                className="btn btn-sm btn-warning ml-3"
                                onClick={() =>
                                  setIsModalOpenEditCategoryProduct(true)
                                }
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </>
                          )}
                        </>
                      </h5>
                      {/* <h3 className="my-3">{product?.name || ""}</h3> */}
                      <h3 className="my-3">
                        <label htmlFor="Category" className="mr-2">
                          Libelle du Site :
                        </label>
                        {isModalOpenEditLibelleSiteProduct ? (
                          <div className="edit-category-form">
                            <input
                              className="form-control"
                              type="text"
                              value={LibelleSiteProduct || product?.nameToFo}
                              onChange={(e) =>
                                setLibelleSiteProduct(e.target.value)
                              }
                            />

                            <div className="float-right mt-2">
                              <button
                                className="btn btn-sm btn-warning mr-2"
                                onClick={(e) => handleEditLibelleSite(e)}
                              >
                                Modifier
                              </button>
                              <button
                                className="btn btn-sm btn-secondary"
                                onClick={() =>
                                  setIsModalOpenEditLibelleSiteProduct(false)
                                }
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {product?.nameToFo || "Pas de Libelle de site"}
                            <button
                              className="btn btn-sm btn-warning ml-3"
                              onClick={() =>
                                setIsModalOpenEditLibelleSiteProduct(true)
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </>
                        )}
                      </h3>
                      <p>
                        <label htmlFor="Description" className="mr-2">
                          Description :
                        </label>

                        {isModalOpenEditDescProduct ? (
                          <div className="edit-category-form">
                            <textarea
                              className="form-control"
                              type="text"
                              value={descriptionProduct || product?.description}
                              onChange={(e) =>
                                setDescriptionProduct(e.target.value)
                              }
                              rows={3}
                            />

                            <div className="float-right mt-2">
                              <button
                                className="btn btn-sm btn-warning mr-2"
                                onClick={(e) => handleEditDesc(e)}
                              >
                                Modifier
                              </button>
                              <button
                                className="btn btn-sm btn-secondary"
                                onClick={() =>
                                  setIsModalOpenEditDescProduct(false)
                                }
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {product?.description || ""}
                            <button
                              className="btn btn-sm btn-warning ml-3"
                              onClick={() =>
                                setIsModalOpenEditDescProduct(true)
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </>
                        )}
                      </p>

                      <hr />
                      <h4>Les couleurs disponibles</h4>
                      <div
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                      >
                        {product.variants &&
                          product.variants.map((variant, index) => (
                            <label
                              key={index}
                              className="btn btn-default text-center active"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <input
                                type="radio"
                                name="color_option"
                                id={`color_option_${index}`}
                                autoComplete="off"
                                checked
                              />
                              {variant?.colorsVariant?.colorName || ""}
                              <div className="mb-2"></div>
                              <Circle
                                colors={variant?.colorsVariant?.colorHexa || ""}
                              />
                            </label>
                          ))}
                      </div>

                      <div className="bg-gray py-2 px-3 mt-4">
                        <h2 className="mb-0">
                          Prix : {product?.price || ""} TND
                        </h2>
                        <h4 className="mt-0">
                          <small>
                            % Promotion :{" "}
                            {product?.sold
                              ? product?.percent
                              : "pas de promotion"}
                          </small>
                        </h4>
                      </div>
                      <div>
                        <h5 className="my-3">
                          <label htmlFor="Category" className="mr-2">
                            Les produits Similaires :
                          </label>
                          <Select
                            defaultValue={selectedProductsDefault || ""}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={listAllProducts}
                            onChange={handleProductChange}
                            value={selectedProducts}
                          />
                          <div className="float-right mt-2">
                            <button
                              className="btn btn-sm btn-warning mr-2"
                              onClick={(e) => addSimilarProducts(e)}
                            >
                              Ajouter
                            </button>
                            <button
                              className="btn btn-sm btn-secondary"
                              onClick={() =>
                                setIsModalOpenEditCategoryProduct(false)
                              }
                            >
                              Annuler
                            </button>
                          </div>
                        </h5>

                        <h5 className="my-3">
                          <label htmlFor="Category" className="mr-2">
                            Les produits Similaires de ce produit :
                          </label>
                          <div className="mt-2">
                            {selectedProductsDefault &&
                              selectedProductsDefault.map((item, index) => (
                                <ul key={index}>
                                  <li>{item?.label || ""}</li>
                                </ul>
                              ))}
                          </div>
                        </h5>

                        <h5 className="my-3">
                          <label htmlFor="IdeeLook" className="mr-2">
                            Idée Look Produit
                          </label>
                          <Select
                            defaultValue={selectedProductsDefaultLook || ""}
                            closeMenuOnSelect={false}
                            components={animatedComponentstwo}
                            isMulti
                            options={listAllProducts}
                            onChange={handleProductLookChange}
                            value={selectedProductsLook}
                          />
                          <div className="float-right mt-2">
                            <button
                              className="btn btn-sm btn-warning mr-2"
                              onClick={(e) => addIdeeLookProducts(e)}
                            >
                              Ajouter
                            </button>
                            <button className="btn btn-sm btn-secondary">
                              Annuler
                            </button>
                          </div>
                        </h5>

                        <h5 className="my-3">
                          <label htmlFor="Category" className="mr-2">
                            Les produits Idée Look de ce produit :
                          </label>
                          <div className="mt-2">
                            {selectedProductsDefaultLook &&
                              selectedProductsDefaultLook.map((item, index) => (
                                <ul key={index}>
                                  <li>{item?.label || ""}</li>
                                </ul>
                              ))}
                          </div>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Variants du produit</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="card-body">
                      <div className="float-right mb-2">
                        {isModalOpenEditDefaultVariantProductFoProduct ? (
                          <>
                            <>
                              <div className="edit-category-form">
                                <select
                                  className="form-control"
                                  value={
                                    selectedDefaultVariantProductFo ||
                                    product?.idVariantDefault
                                      ? product?.idVariantDefault
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setSelectedDefaultVariantProductFo(
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">
                                    ---Pas de variant default---
                                  </option>
                                  {product?.variants &&
                                    product.variants
                                      .filter((variant) => variant.visible)
                                      .map((variant, index) => (
                                        <option
                                          key={variant.id}
                                          value={variant.id}
                                        >
                                          variant {index + 1} -{" "}
                                          {variant.codeABarre || ""}
                                        </option>
                                      ))}
                                </select>{" "}
                                <div className="float-right mt-2">
                                  <button
                                    className="btn btn-sm btn-warning mr-2"
                                    onClick={(e) =>
                                      handleEditDefaultVariantProductFo(e)
                                    }
                                  >
                                    Modifier
                                  </button>
                                  <button
                                    className="btn btn-sm btn-secondary"
                                    onClick={() =>
                                      setIsModalOpenEditDefaultVariantProductFoProduct(
                                        false
                                      )
                                    }
                                  >
                                    Annuler
                                  </button>
                                </div>
                              </div>
                            </>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-sm btn-dark"
                              onClick={() =>
                                setIsModalOpenEditDefaultVariantProductFoProduct(
                                  true
                                )
                              }
                            >
                              {product?.idVariantDefault ? (
                                <>
                                  <b> Modifier variant Default</b>
                                </>
                              ) : (
                                <>
                                  <b> Ajouter variant Default</b>
                                </>
                              )}
                            </button>
                          </>
                        )}
                      </div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th># Code à barre</th>
                            <th>Couleur</th>
                            <th>Nom Couleur</th>
                            <th>Tailles</th>
                            <th>Visibilité Sur Site</th>
                            <th>Nb Images</th>
                            <th>variant default</th>
                            <th>Modifier Ordre Images</th>
                            <th>Stock</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {product?.variants &&
                            product?.variants.map((variant, index) => (
                              <tr key={index}>
                                <td>{variant?.codeABarre || ""}</td>
                                <td>
                                  {variant?.colorsVariant?.colorHexa || ""}
                                </td>
                                <td>
                                  {variant?.colorsVariant?.colorName || ""}
                                </td>
                                <td>
                                  {variant.sizesVariant &&
                                  variant.sizesVariant.length > 0
                                    ? variant.sizesVariant.map(
                                        (itemSize, indexsize) => (
                                          <React.Fragment key={indexsize}>
                                            {indexsize > 0 && ", "}
                                            {itemSize?.size || ""}
                                          </React.Fragment>
                                        )
                                      )
                                    : null}
                                </td>
                                <td>{variant?.visible ? "oui" : "non"}</td>
                                <td>{variant?.nbImages || 0}</td>
                                <td>{variant?.defaultToFo ? "Oui" : "Non"}</td>
                                <td>
                                  <div className="text-center">
                                    <button
                                      className="btn btn-sm btn-dark"
                                      onClick={() =>
                                        openModalEditOrderImagesVariant(variant)
                                      }
                                    >
                                      Modifier
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-warning"
                                    onClick={() =>
                                      openModalShowStock(variant?.sizesVariant)
                                    }
                                  >
                                    voir
                                  </button>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignContent: "center",
                                    }}
                                  >
                                    <button
                                      style={{ width: 40 }}
                                      className={`btn btn-sm ${
                                        variant?.visible
                                          ? "btn-primary"
                                          : "btn-secondary"
                                      }`}
                                      onClick={() =>
                                        visibledVariantById(variant?.id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          variant?.visible ? faEye : faEyeSlash
                                        }
                                      />
                                    </button>
                                    <div className="mr-3"></div>
                                    <button
                                      className="btn btn-sm btn-warning"
                                      onClick={() => openModalEdit(index)}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <div className="mr-3"></div>
                                    <button
                                      style={{ width: 40 }}
                                      className={`btn btn-sm ${
                                        variant?.archived
                                          ? "btn-success"
                                          : "btn-danger"
                                      }`}
                                      onClick={() =>
                                        archiverVariantById(variant?.id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          variant?.archived
                                            ? faRecycle
                                            : faTrash
                                        }
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          {isModalOpenEdit && (
                            <UpdateImagesVariantPopup
                              setIsSaved={setIsSaved}
                              isModalOpenEdit={isModalOpenEdit}
                              setIsModalOpenEdit={setIsModalOpenEdit}
                              variantImages={
                                product.variants[index].imagesVariant
                              }
                              idVariant={product.variants[index].id}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {isModalOpenEditOrderImagesVariant && (
                <UpdateOrderImagesVariant
                  setIsSaved={setIsSaved}
                  isModalOpenEditOrderImagesVariant={
                    isModalOpenEditOrderImagesVariant
                  }
                  setIsModalOpenEditOrderImagesVariant={
                    setIsModalOpenEditOrderImagesVariant
                  }
                  variantSelected={variantSelectedImagesOrder}
                />
              )}
              {isModalOpenShowStock && (
                <ShowVariantStock
                  setIsSaved={setIsSaved}
                  setIsModalOpenShowStock={setIsModalOpenShowStock}
                  isModalOpenShowStock={isModalOpenShowStock}
                  sizesVariantStock={sizesVariantStock}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DetailsProduct;
